// import React, { useState } from "react";
import Logo from "../images/logo.png";
// import {
//   deactivateOrganisation,
//   ioiuserSelector,
//   logOut,
// } from "../api/ioiUser";
// import { useDispatch, useSelector } from "react-redux";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import { Avatar, ListItemIcon, Tooltip } from "@mui/material";
// import IconButton from "@mui/material/IconButton";
// import { Logout } from "@mui/icons-material";
// import { Box, Modal } from "@mui/material";
// import { Button, ModalDialog } from "@mui/joy";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "1000px",
//   height: "500px",
//   bgcolor: "background.paper",
//   border: "px solid #000",
//   boxShadow: 2,
//   p: 2,
// };

// export default function ProfileNavbar({ profile }) {
//   const Text = `Coming Soon`;

//   const { token, user } = useSelector(ioiuserSelector);
//   const dispatch = useDispatch();

//   const [anchorEl, setAnchorEl] = useState(null);
//   const [openModel, setOpenModel] = useState(false);
//   const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // State for mobile menu

//   const open = Boolean(anchorEl);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const openModelFunction = () => {
//     setAnchorEl(null);
//     setOpenModel(true);
//   };

//   const handelModelClose = () => {
//     setOpenModel(false);
//   };

//   const deactiveAccount = () => {
//     dispatch(deactivateOrganisation(user?._id, token));
//     setOpenModel(false);
//   };

//   // Function to toggle mobile menu
//   const toggleMobileMenu = () => {
//     setMobileMenuOpen(!mobileMenuOpen);
//   };

//   return (
//     <div className="sticky top-0 z-50">
//       <section style={{ fontFamily: "Lato" }}>
//         <nav className="relative flex items-center justify-between p-2 bg-gray-100">
//           <div className="w-full xl:w-auto px-2 xl:mr-12">
//             <div className="flex items-center justify-between">
//               <a className="inline-flex items-center" href="#">
//                 <img src={Logo} alt="Logo" />
//               </a>
//               <div className="xl:hidden">
//                 <button
//                   className="navbar-burger text-gray-400 hover:text-gray-300 focus:outline-none"
//                   onClick={toggleMobileMenu}
//                 >
//                   <svg
//                     width="20"
//                     height="12"
//                     viewBox="0 0 20 12"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       d="M1 2H19C19.2652 2 19.5196 1.89464 19.7071 1.70711C19.8946 1.51957 20 1.26522 20 1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2ZM19 10H1C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H19C19.2652 12 19.5196 11.8946 19.7071 11.7071C19.8946 11.5196 20 11.2652 20 11C20 10.7348 19.8946 10.4804 19.7071 10.2929C19.5196 10.1054 19.2652 10 19 10ZM19 5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H19C19.2652 7 19.5196 6.89464 19.7071 6.70711C19.8946 6.51957 20 6.26522 20 6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5Z"
//                       fill="currentColor"
//                     ></path>
//                   </svg>
//                 </button>
//               </div>
//             </div>
//           </div>

//           {/* Mobile menu */}
//           {mobileMenuOpen && (
//             <div
//               className="xl:hidden bg-gray-100 absolute right-0 w-full"
//               style={{ top: "100%" }}
//             >
//               {/* <ul className="flex flex-col items-start w-50"> */}
//                 {/* <li> */}
//                   <div className="items-center -mb-2">
//                     <div>
//                       <IconButton
//                         color="inherit"
//                         id="basic-button"
//                         aria-controls={open ? "basic-menu" : undefined}
//                         aria-haspopup="true"
//                         aria-expanded={open ? "true" : undefined}
//                         onClick={handleClick}
//                       >
//                         <Avatar
//                           alt=""
//                           src={
//                             user?.profile_completion?.logo
//                               ? user?.profile_completion?.logo
//                               : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
//                           }
//                         />
//                       </IconButton>
//                       <Menu
//                         id="basic-menu"
//                         anchorEl={anchorEl}
//                         open={open}
//                         onClose={handleClose}
//                         MenuListProps={{
//                           "aria-labelledby": "basic-button",
//                         }}
//                       >
//                         <MenuItem
//                           onClick={() => {
//                             dispatch(logOut());
//                           }}
//                         >
//                           <ListItemIcon className="px-4">
//                             <Logout fontSize="medium" />
//                             Logout
//                           </ListItemIcon>
//                         </MenuItem>
//                       </Menu>
//                     </div>
//                   </div>
//                 {/* </li> */}
//               {/* </ul> */}
//             </div>
//           )}

//           {/* Desktop menu */}
//           <div className="hidden xl:block w-full md:w-auto px-2">
//             <div className="md:flex items-center">
//               <div className="w-full md:w-auto mb-6 md:mb-0 md:mr-4">
//                 <div className="flex flex-wrap items-center -mb-2">
//                   <div>
//                     <IconButton
//                       color="inherit"
//                       id="basic-button"
//                       aria-controls={open ? "basic-menu" : undefined}
//                       aria-haspopup="true"
//                       aria-expanded={open ? "true" : undefined}
//                       onClick={handleClick}
//                     >
//                       <Avatar
//                         alt=""
//                         src={
//                           user?.profile_completion?.logo
//                             ? user?.profile_completion?.logo
//                             : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
//                         }
//                       />
//                     </IconButton>
//                     <Menu
//                       id="basic-menu"
//                       anchorEl={anchorEl}
//                       open={open}
//                       onClose={handleClose}
//                       MenuListProps={{
//                         "aria-labelledby": "basic-button",
//                       }}
//                     >
//                       <MenuItem
//                         onClick={() => {
//                           dispatch(logOut());
//                         }}
//                       >
//                         <ListItemIcon className="px-4">
//                           <Logout fontSize="medium" />
//                           Logout
//                         </ListItemIcon>
//                       </MenuItem>
//                     </Menu>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </nav>
//       </section>

//       <Modal
//         open={openModel}
//         onClose={handelModelClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <ModalDialog
//             setOpenModel={setOpenModel}
//             jobPost={true}
//             buttonText={"Create Job"}
//             title={"Post a Job"}
//             Text={Text}
//           />
//         </Box>
//       </Modal>
//     </div>
//   );
// }




import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import { logOut, ioiuserSelector } from "../api/ioiUser";

export default function ProfileNavbar() {
  const { user } = useSelector(ioiuserSelector);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="sticky top-0 z-50">
      <nav className="relative flex items-center justify-between p-2 bg-gray-100">
        <div className="p-2 w-full">
          <div className="flex items-center justify-between ">
            <a className="inline-flex items-center" href="#">
              <img
                src={Logo} 
                alt="Logo"
              />
            </a>

            {/* Avatar and Logout Menu */}
            <div className="ml-auto flex justify-end items-end ">
              {" "}
            
              <IconButton
                color="inherit"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                              onClick={handleClick}
                              style={{
                                  display:"flex",
                              }}
              >
                <Avatar
                  alt=""
                  src={
                    user?.profile_completion?.logo
                      ? user?.profile_completion?.logo
                      : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
                  }
                />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    dispatch(logOut());
                  }}
                >
                  <ListItemIcon className="px-4">
                    <Logout fontSize="medium" />
                    Logout
                  </ListItemIcon>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import "./Publish.css";
import WorkIcon from "@mui/icons-material/Work";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteJob, fetchPostedJobs, jobsSelector } from "../../api/jobs";

import { fetchIOIProfile, ioiuserSelector } from "../../api/ioiUser";

import PublishIcon from "@mui/icons-material/Publish";
import { CircularProgress } from "@mui/material";

import { LiaCertificateSolid } from "react-icons/lia";
// import { IoDocumentText } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { FaRegNewspaper } from "react-icons/fa6";
import { HiMiniClipboardDocumentCheck } from "react-icons/hi2";
import { MdBookmarkAdded } from "react-icons/md";

function Generaldocuments() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { jobs } = useSelector(jobsSelector);
  const [type, setType] = useState("jobs");
  const { user, token } = useSelector(ioiuserSelector);
  const nav = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [deletionId, setDeletionId] = useState("");

  ////console.log(jobs, 'kk')
  const handleOpen = (id) => {
    setDeletionId(id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handelDelete = () => {
    dispatch(deleteJob(deletionId, user?._id));
    setOpen(false);
  };

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  useEffect(() => {
    dispatch(fetchIOIProfile(token));
  }, [token]);

  useEffect(() => {
    dispatch(fetchPostedJobs(user?._id));
  }, [user]);

   const [loading, setLoading] = useState(true); // Add loading state

   // Simulate fetching data or waiting for a response
   useEffect(() => {
     setTimeout(() => {
       setLoading(true); // Set loading to false after content is ready
     }, 2000); // 2-second delay to simulate loading
   }, []);
const trimText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  const trimmedText = text.substr(0, maxLength);
  return (
    trimmedText.substr(
      0,
      Math.min(trimmedText.length, trimmedText.lastIndexOf(" "))
    ) + "..."
  );
};
  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <div class="w-full mb-6">
                <div class="relative p-6 text-center bg-gray-100 rounded-xl">
                  <img
                    class="block mx-auto mb-5 w-28 h-28 rounded-full"
                    src={
                      user?.profile_completion?.logo
                        ? user?.profile_completion?.logo
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    alt=""
                  />
                  <h4 class="text-lg text-[#0F3D79] font-bold mb-3">
                    {user?.name}
                  </h4>
                  <div class="flex items-center justify-center mb-6">
                    <p class="text-md font-semibold text-gray-500">
                      {user?.sub_category}
                    </p>
                  </div>
                </div>
              </div>
              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/publish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <LiaCertificateSolid />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Patent Certificates
                      </h4>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/generaldocuments">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <IoDocumentText />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        General Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/Researchpapers">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <FaRegNewspaper />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Research papers
                      </h4>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/MyPublish">
                  <div
                    className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("MyPublish")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <HiMiniClipboardDocumentCheck />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        My Published Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/save_publish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <MdBookmarkAdded />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Saved Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div className="w-full lg:w-[78%] px-3">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="flex justify-center items-center flex-col">
                  <p className="mt-4 text-xl text-gray-700">
                    Adding General Documents is In Progress. Please check back
                    later.
                  </p>
                  <br></br>
                  <CircularProgress />
                  {/* <FaHammer className="mt-4 text-4xl text-yellow-600 animate-spin" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Generaldocuments;

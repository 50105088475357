import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import { Typography } from "@material-ui/core";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { keyUri } from "../../key";
import { toast } from "react-toastify";

export default function ResetPassword() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const [token, setToken] = useState();
  const [rules, setRules] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get("token");

    if (token) {
      setToken(token);
    }
  }, []);

  const content = {
    brand: { image: { Logo }, width: 120 },
    "02_secondary-action": "Don't have an account?Sign up here",
    "02_tertiary-action": "Forgot password?",
  };

  let brand;

  if (content.brand.image.Logo) {
    brand = (
      <img src={content.brand.image.Logo} alt="" width={content.brand.width} />
    );
  } else {
    brand = content.brand.text || "";
  }

  const isPasswordValid = (password) => {
    const minLength = 8;
    const uppercaseRegex = /[A-Z]/;
    const numbercaseRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    const regex = /\s/;

    return (
      password.length >= minLength &&
      uppercaseRegex.test(password) &&
      specialCharRegex.test(password) &&
      numbercaseRegex.test(password) &&
      !regex.test(password)
    );
  };

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;

    if (name === "password") {
      const result = isPasswordValid(value);
      !result
        ? setRules(
            "Password must be atleast 8 characters long. Use minimum 1 special charcter, 1 number and 1 uppercase letter without any spaces"
          )
        : setRules("");
    }

    if (name === "password" && password.confirmPassword !== "") {
      ////console.log("Confirm");
      setPassword({ confirmPassword: "" });
      setPasswordMismatch(false);
    }
    if (name == "confirmPassword") {
      if (password.password !== value) {
        setPasswordMismatch(true);
      } else setPasswordMismatch(false);
    }
    setPassword((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onFinish = async (e) => {
    e.preventDefault();
    if (
      password?.password === password?.confirmPassword &&
      !passwordMismatch &&
      rules == "" &&
      password?.password != ""
    ) {
      if (token) {
        try {
          const { data } = await axios.post(
            `${keyUri.BACKEND_URI}/users/ioi-reset-password?token=${token}`,
            { password: password?.password }
          );
          navigate("/");
          toast.success(`${data?.message}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeButton: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } catch (error) {
          toast.error(`${error?.data?.message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeButton: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            navigate("/forgot-password");
          }, 3000);
        }
      }
    } else {
      toast.warning("Please check password entered", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <Box className="">
        <Container maxWidth="xs">
          <form onSubmit={onFinish}>
            <Box pt={8} pb={10}>
              <Box mb={1} textAlign="center">
                <div
                  style={{
                    marginLeft: "33%",
                    marginBottom: "5%",
                    marginTop: "30%",
                  }}
                >
                  <Link href="/" variant="h4" color="inherit" underline="none">
                    {brand}
                  </Link>
                </div>
                <Box textAlign="center">
                  <Typography
                    variant="h5"
                    component="h2"
                    className="text-center text-indigo-900"
                  >
                    Reset Password
                  </Typography>
                </Box>
              </Box>

              <div>
                <FormControl
                  fullWidth
                  sx={{ width: "100%", marginTop: "10px" }}
                  size="small"
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    onChange={handlePasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    value={password?.password}
                    error={Boolean(rules != "")}
                  />
                  <FormHelperText
                    id="outlined-password-helper-text"
                    style={{ color: "red" }}
                  >
                    {rules}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ width: "100%", marginTop: "10px" }}
                  size="small"
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    name="confirmPassword"
                    type={showPassword1 ? "text" : "password"}
                    onChange={handlePasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                    value={password?.confirmPassword}
                    error={Boolean(passwordMismatch)}
                  />
                  <FormHelperText
                    id="outlined-confirm-password-helper-text"
                    style={{ color: "red" }}
                  >
                    {passwordMismatch ? "Password doesn not match" : ""}
                  </FormHelperText>
                </FormControl>

                <Stack direction="row" spacing={0} my={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      fontSize: "12px",
                      backgroundColor: "#0066A2",
                      color: "white",
                      marginLeft: "60%",
                    }}
                  >
                    Reset Password
                  </Button>
                </Stack>
              </div>
            </Box>
          </form>
        </Container>
      </Box>
      <div class=" border-t border-gray-200 mt-40 md:mt-80">
        <div class="lg:flex items-center py-3 float-right md:mr-60 ">
          <div class="flex lg:mb-0 items-center">
            <a
              class="inline-block mr-4 sm:mr-10 text-lg font-semibold text-indigo-900 hover:text-indigo-800"
              target="_blank"
              href="/privacy"
            >
              Privacy Policy
            </a>
            <a
              class="inline-block mr-4 sm:mr-10 text-lg font-semibold text-indigo-900 hover:text-indigo-800"
              target="_blank"
              href="/agreement"
            >
              User Agreement
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOneJob, jobsSelector } from "../../api/jobs";
import { useLocation, useNavigate, useParams } from "react-router";
import WorkIcon from "@mui/icons-material/Work";
import { NavLink } from "react-router-dom";
import { ioiuserSelector } from "../../api/ioiUser";
import { fetchPostedJobs } from "../../api/jobs";
import { Box, Typography } from "@mui/material";
import ApplicantCard from "./ApplicantCard";

import { MdPostAdd, MdSaveAs } from "react-icons/md";
import { BsFilterSquareFill } from "react-icons/bs";

function Applicants() {
  const { currentjob } = useSelector(jobsSelector);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { id } = useParams();
  const { user, token } = useSelector(ioiuserSelector);
  const [loading, setLoading] = useState(true); // Loading state for applicants

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(fetchOneJob(id)); // Fetch job details
    setLoading(true); // Set loading to true when fetching
  }, [id, dispatch]);

  useEffect(() => {
    if (currentjob) {
      setLoading(false); // Set loading to false once job data is available
    }
  }, [currentjob]);

  useEffect(() => {
    dispatch(fetchPostedJobs(user?._id));
  }, [user, dispatch]);

  // Optional: You can use the loading state to show a loading indicator
  if (loading) {
    return <div>Loading applicants...</div>; // Simple loading indicator
  }

  return (
    <div style={{ fontFamily: "Lato" }}>
      <section className="py-8">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <div className="w-full mb-6">
                <div className="relative p-6 text-center bg-gray-100 rounded-xl">
                  <img
                    className="block mx-auto mb-5 w-28 h-28 rounded-full"
                    src={
                      user?.profile_completion?.logo
                        ? user?.profile_completion?.logo
                        : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
                    }
                    alt=""
                  />
                  <h4 className="text-lg text-[#0F3D79] font-bold mb-3">
                    {user?.name}
                  </h4>
                  <div className="flex items-center justify-center mb-6">
                    <p className="text-md font-semibold text-gray-500">
                      {user?.sub_category}
                    </p>
                  </div>
                </div>
              </div>
              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/jobs">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <MdPostAdd />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Jobs Posted
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to={`/applicants/${id}`}>
                  <div
                    className="flex group py-3 px-2 sm:px-8 items-center hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                    style={{
                      backgroundColor: pathname.includes("applicants")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <MdSaveAs />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Job Applicants
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to={`/shortlist/${id}`}>
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <BsFilterSquareFill />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Job Shortlists
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="w-full lg:w-[78%] px-3">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="flex flex-wrap">
                  {currentjob?.applicants?.length > 0 ? (
                    currentjob?.applicants.map((item, i) => {
                      return (
                        <ApplicantCard applicants={item} index={i} jobId={id} />
                      );
                    })
                  ) : ( 
                    <div>No applicants yet.</div> 
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Applicants;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { nanoid } from "@reduxjs/toolkit";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../../shared/firebaseStorage";
import { createExplore } from "../../api/expore"; // API action to create ad
import { ioiuserSelector } from "../../api/ioiUser";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Autocomplete, TextField, Checkbox, Chip } from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import { Keyareas } from "../../shared/constants"; // Assuming this is the correct import
import { createAdds } from "../../api/expore";
import ExploreIcon from "@mui/icons-material/Explore";

function CreateAdd() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useSelector(ioiuserSelector); // Get user data from Redux store

  const [adds, setAdds] = useState({
    add_title: "",
    description: "",
    add_type: "",
  });
  const [selectedKeyArea, setSelectedKeyArea] = useState([]); // Holds selected audiences
  const [documents, setDocuments] = useState([]); // Array to hold uploaded file URLs
  const [imagePercent, setImagePercent] = useState(0);
  const [uploadSuccessMsg, setUploadSuccessMsg] = useState("");

  const handleChange = (e) => {
    setAdds({ ...adds, [e.target.name]: e.target.value });
  };

  // Handle file input change for image/video upload
  const handleFileInputChange = (event) => {
    setImagePercent(0);
    const selectedFiles = event.target.files;

    if (selectedFiles && selectedFiles.length > 0) {
      Array.from(selectedFiles).forEach((selectedFile) => {
        // Check if file size is less than 50 MB
        const isLt50MB = selectedFile.size / 1024 / 1024 < 50;

        if (!isLt50MB) {
          toast.warning("Each file must be less than 50 MB", {
            position: "top-center",
            autoClose: 2000,
            theme: "light",
          });
          return; // Skip this file if it exceeds the 50 MB limit
        }

        const storageRef = ref(storage, `/ads/${nanoid() + selectedFile.name}`);
        const uploadFile = uploadBytesResumable(storageRef, selectedFile);

        uploadFile.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setImagePercent(percent);
            if (percent === 100) {
              setUploadSuccessMsg("File Uploaded Successfully.");
            }
          },
          (err) => {
            console.error(err);
          },
          () => {
            getDownloadURL(uploadFile.snapshot.ref).then((url) => {
              setDocuments((prevDocs) => [...prevDocs, url]); // Append each file URL to the array
            });
          }
        );
      });
    }
  };

  // Handle target audience selection
  const handleKeyAreaSelection = (newValue) => {
    if (newValue.length <= 4) {
      setSelectedKeyArea(newValue);
    } else {
      toast.warning("You can only select up to 4 domains", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  };

  // Handle form submission
  const submitHandler = async (e) => {
    e.preventDefault();

    const { add_title, description, add_type } = adds;

    if (
      add_title &&
      documents.length > 0 &&
      description &&
      add_type &&
      selectedKeyArea.length
    ) {
      const addInfo = {
        ...adds,
        document: documents, // Array of uploaded file URLs
        targetAudience: selectedKeyArea.map((item) => item.title), // Store selected audiences
        add_posted_by_ioi: user._id, // Assuming this is how you link to the user
      };

      await dispatch(createAdds(addInfo)); // Dispatch the create action
      // toast.success("Ad created successfully!");
      setTimeout(() => {
        navigate(-1); // Navigate back after the delay
      }, 3000); //
    } else {
      toast.warning("Please fill all the fields", {
        position: "top-center",
        autoClose: 1000,
        theme: "light",
      });
    }
  };

  return (
    <section className="py-8" style={{ fontFamily: "Lato" }}>
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap -mx-3">
          <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
            <div class="w-full mb-6">
              <div class="relative p-6 text-center bg-gray-100 rounded-xl">
                <img
                  class="block mx-auto mb-5 w-28 h-28 rounded-full"
                  src={
                    user?.profile_completion?.logo
                      ? user?.profile_completion?.logo
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  }
                  alt=""
                />
                <h4 class="text-lg text-[#0F3D79] font-bold mb-3">
                  {user?.name}
                </h4>
                <div class="flex items-center justify-center mb-6">
                  <p class="text-md font-semibold text-gray-500">
                    {user?.sub_category}
                  </p>
                </div>
              </div>
            </div>
            <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/Conferences">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Conferences
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Competitions">
                  <div
                    class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("Competitions")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Competitions
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Otherevents">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Other Events
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Ads">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Myevents">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        My Events
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/SavedAds">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Saved Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/MyAds">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                       My Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
          </div>
          <div className="w-full lg:w-[78%] px-3">
            <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
              <div className="md:max-w-6xl mx-auto">
                <div className="my-4 mx-0">
                  <h1 className="text-lg font-semibold text-[#0F3D79]">
                    Create an Ad
                  </h1>
                </div>

                <form onSubmit={submitHandler}>
                  {/* Ad Type */}
                  <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Select Ad Type
                    </label>
                    <select
                      name="add_type"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                      onChange={handleChange}
                      value={adds.add_type}
                    >
                      <option value="">Select Product / Service</option>
                      <option value="product">Product</option>
                      <option value="service">Service</option>
                    </select>
                  </div>

                  {/* Ad Title */}
                  <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Title of the Ad
                    </label>
                    <input
                      name="add_title"
                      type="text"
                      placeholder="Enter title of the ad"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                      value={adds.add_title}
                      onChange={handleChange}
                    />
                  </div>

                  {/* Ad Description */}
                  <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Description
                    </label>
                    <textarea
                      name="description"
                      placeholder="Enter ad description"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                      rows="4"
                      value={adds.description}
                      onChange={handleChange}
                    />
                  </div>

                  {/* Target Audience */}
                  <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Target Audience
                    </label>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={Keyareas}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.title}
                      value={selectedKeyArea}
                      onChange={(event, newValue) =>
                        handleKeyAreaSelection(newValue)
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.title}
                        </li>
                      )}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => (
                          <Chip
                            key={option.title}
                            label={option.title}
                            {...getTagProps({ index })}
                            onDelete={() =>
                              setSelectedKeyArea(
                                selectedKeyArea.filter(
                                  (item) => item !== option
                                )
                              )
                            }
                          />
                        ))
                      }
                      style={{ width: "100%", marginTop: "10px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Target Audience"
                          placeholder="Select up to 4 domains"
                        />
                      )}
                    />
                  </div>

                  {/* Upload File (Image/Video) */}
                  <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Upload Image / Video
                    </label>
                    <div className="relative flex items-center border-dashed border-2 border-gray-300 rounded p-4">
                      <input
                        type="file"
                        accept="image/*,video/*"
                        multiple
                        className="absolute inset-0 opacity-0 cursor-pointer"
                        onChange={handleFileInputChange}
                      />
                      <span className="flex-1 text-gray-700">
                        {documents.length > 0
                          ? `${documents.length} Files Uploaded`
                          : "Select or upload image/video"}
                      </span>
                      <CloudUploadIcon className="w-6 h-6 text-blue-500" />
                    </div>
                    {imagePercent > 0 && (
                      <p className="text-sm text-gray-500 mt-2">
                        Upload Progress: {imagePercent}%
                      </p>
                    )}
                  </div>

                  {/* Submit Button */}
                  <div className="flex items-center justify-between">
                    <button
                      className="bg-[#002357] hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
                      type="submit"
                    >
                      Create Ad
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateAdd;

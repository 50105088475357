import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIndividualProfile,
  individualUserSelector,
} from "../../api/individualUser";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/joy";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { fetchOneJob, jobShortlist, jobsSelector } from "../../api/jobs";

export default function Profileview() {
  const dispatch = useDispatch();
  const { applicantId, id } = useParams();
  const { current_individual_user } = useSelector(individualUserSelector);
  const { currentjob } = useSelector(jobsSelector);
  const nav = useNavigate();

  ////console.log(currentjob);

  useEffect(() => {
    dispatch(getIndividualProfile(applicantId));
    dispatch(fetchOneJob(id));
  }, [applicantId]);

  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container mx-auto">
          {/* <Button variant='outlined' sx={{color:'gray', borderColor:'#F3F4F6',marginBottom:'20px'}}onClick={()=>nav(-1)}><KeyboardBackspaceIcon/></Button> */}
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[25%] px-3">
              <div class="mb-6">
                <div class="relative bg-gray-100 shadow rounded-lg overflow-hidden h-[300px]">
                  <img
                    class="relative w-32 h-32 mb-4 mt-4 mx-auto rounded-full object-cover object-right"
                    src={
                      current_individual_user?.profile_completion?.profile_pic
                        ? current_individual_user?.profile_completion
                            ?.profile_pic
                        : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
                    }
                    alt=""
                  />
                  <div class="px-6 pb-5 text-center">
                    <p class="text-lg font-semibold text-[#0F3D79]">
                      {current_individual_user?.name}
                    </p>
                    <p class="text-md font-semibold text-gray-500">
                      {current_individual_user?.profile_completion?.category ===
                      ""
                        ? ""
                        : current_individual_user?.profile_completion
                            ?.category === "Student"
                        ? "Student"
                        : current_individual_user?.profile_completion?.designation
                            ?.charAt(0)
                            .toUpperCase() +
                          current_individual_user?.profile_completion?.designation?.slice(
                            1
                          )}
                    </p>
                    <p class="text-md font-semibold text-gray-500">
                      {current_individual_user?.email}
                    </p>

                    <div>
                      {currentjob?.shortlisted?.find((item) => {
                        return item?._id == current_individual_user?._id;
                      }) ? (
                        <div class="text-center mt-3">
                          <a class="relative group inline-block py-2 px-7 border font-semibold text-blue-900 hover:text-white rounded-full bg-white hover:bg-indigo-800 transition duration-300 overflow-hidden cursor-pointer">
                            <span class="relative px-3 text-lg ">
                              Shortlisted
                            </span>
                          </a>
                        </div>
                      ) : (
                        <div class="text-center mt-3">
                          <a
                            class="relative group inline-block py-2 px-7 border font-semibold text-blue-900 hover:text-white rounded-full bg-white hover:bg-indigo-800 transition duration-300 overflow-hidden cursor-pointer"
                            onClick={() =>
                              dispatch(
                                jobShortlist(currentjob?._id, {
                                  id: current_individual_user?._id,
                                })
                              )
                            }
                          >
                            <span class="relative px-3 text-lg ">
                              Shortlist
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div class="p-6 bg-gray-100 rounded-xl h-[300px]">
                <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                  <div class="w-auto px-4">
                    <h3 class="text-lg font-semibold text-[#0F3D79]">
                      Interested Domains
                    </h3>
                  </div>
                  <div class="w-full">
                    <div class="h-full py-3 px-5 rounded-xl">
                      <div class="mb-8 -mx-4">
                        <div class="-mb-3 text-start white-space-no-wrap">
                          {current_individual_user?.profile_completion?.skills?.map(
                            (item, i) => {
                              return (
                                i < 6 && (
                                  <p class="px-2 pl-5 py-1 my-1 mb-1  text-gray-500 bg-gray-200 text-sm font-medium leading-6 rounded-full">
                                    {item}
                                  </p>
                                )
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-[50%] px-3">
              <div class="container mx-auto">
                <div class="relative bg-gray-100 shadow rounded-lg overflow-hidden  h-[300px]">
                  <div
                    className="px-3 py-3"
                    style={{
                      display:
                        current_individual_user?.profile_completion?.category ==
                        "Student"
                          ? "block"
                          : "none",
                    }}
                  >
                    <div className="flex p-2 ">
                      <div className="w-1/2 mr-5">
                        <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                          <div class="w-auto px-4 ">
                            <h3 class="text-lg font-semibold text-[#0F3D79] mb-4">
                              Education Details
                            </h3>
                          </div>
                        </div>

                        <div className="mt-5 text-md font-medium text-gray-500">
                          <p class="font-semibold" style={{ color: "#000" }}>
                            {
                              current_individual_user?.profile_completion
                                ?.education_details_1?.institute_name1
                            }
                          </p>
                          <p>
                            {
                              current_individual_user?.profile_completion
                                ?.education_details_1?.education_level1
                            }
                          </p>
                          <p>
                            {
                              current_individual_user?.profile_completion
                                ?.education_details_1?.course_name1
                            }
                          </p>
                          <p>
                            {current_individual_user?.profile_completion
                              ?.education_details_1?.start_year1 &&
                            current_individual_user?.profile_completion
                              ?.education_details_1?.end_year1
                              ? `${dayjs(
                                  current_individual_user.profile_completion
                                    .education_details_1.start_year1
                                ).format("YYYY")}-${dayjs(
                                  current_individual_user.profile_completion
                                    .education_details_1.end_year1
                                ).format("YYYY")}`
                              : ""}
                          </p>
                        </div>

                        <div className="mt-5 text-md font-medium text-gray-500">
                          <p
                            className="font-semibold"
                            style={{ color: "#000" }}
                          >
                            {
                              current_individual_user?.profile_completion
                                ?.education_details_2?.institute_name2
                            }
                          </p>
                          <p>
                            {
                              current_individual_user?.profile_completion
                                ?.education_details_2?.education_level2
                            }
                          </p>
                          <p>
                            {
                              current_individual_user?.profile_completion
                                ?.education_details_2?.course_name2
                            }
                          </p>
                          <p>
                            {
                              current_individual_user?.profile_completion
                                ?.education_details_2?.start_year2 &&
                              current_individual_user?.profile_completion
                                ?.education_details_2?.end_year2
                                ? `${dayjs(
                                    current_individual_user.profile_completion
                                      .education_details_2.start_year2
                                  ).format("YYYY")}-${dayjs(
                                    current_individual_user.profile_completion
                                      .education_details_2.end_year2
                                  ).format("YYYY")}`
                                : "" //display nothing
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="px-3 py-3"
                    style={{
                      display:
                        current_individual_user?.profile_completion
                          ?.category !== "Student"
                          ? "block"
                          : "none",
                    }}
                  >
                    <div className="flex p-2 ">
                      <div className="w-1/2 mr-5">
                        <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                          <div class="w-auto px-4 ">
                            <h3 class="text-lg font-semibold text-[#0F3D79] mb-4">
                              Education Details
                            </h3>
                          </div>
                        </div>

                        <div className="mt-5 text-md font-medium text-gray-500">
                          <p class=" text-md font-semibold text-gray-800">
                            {
                              current_individual_user?.profile_completion
                                ?.education_details_1?.institute_name1
                            }
                          </p>
                          <p>
                            {
                              current_individual_user?.profile_completion
                                ?.education_details_1?.education_level1
                            }
                          </p>
                          <p>
                            {
                              current_individual_user?.profile_completion
                                ?.education_details_1?.course_name1
                            }
                          </p>
                          <p>
                            {current_individual_user?.profile_completion
                              ?.education_details_1?.start_year1 &&
                            current_individual_user?.profile_completion
                              ?.education_details_1?.end_year1
                              ? `${dayjs(
                                  current_individual_user.profile_completion
                                    .education_details_1.start_year1
                                ).format("YYYY")}-${
                                  current_individual_user.profile_completion
                                    .education_details_1.end_year1 === "present"
                                    ? "Present" // Handle "present" case
                                    : dayjs(
                                        current_individual_user
                                          .profile_completion
                                          .education_details_1.end_year1
                                      ).format("YYYY")
                                }`
                              : ""}
                          </p>
                        </div>

                        <div className="mt-5 text-md font-medium text-gray-500">
                          <p class="text-md font-semibold text-gray-800">
                            {
                              current_individual_user?.profile_completion
                                ?.education_details_2?.institute_name2
                            }
                          </p>
                          <p>
                            {
                              current_individual_user?.profile_completion
                                ?.education_details_2?.education_level2
                            }
                          </p>
                          <p>
                            {
                              current_individual_user?.profile_completion
                                ?.education_details_2?.course_name2
                            }
                          </p>
                          <p>
                            {
                              current_individual_user?.profile_completion
                                ?.education_details_2?.start_year2 &&
                              current_individual_user?.profile_completion
                                ?.education_details_2?.end_year2
                                ? `${dayjs(
                                    current_individual_user.profile_completion
                                      .education_details_2.start_year2
                                  ).format("YYYY")}-${dayjs(
                                    current_individual_user.profile_completion
                                      .education_details_2.end_year2
                                  ).format("YYYY")}`
                                : "" //display nothing
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container mx-auto px-1 mt-6">
                <div class="relative bg-gray-100 shadow rounded-lg overflow-hidden  h-[300px]">
                  <div
                    className="px-3 py-3"
                    style={{
                      display:
                        current_individual_user?.profile_completion?.category ==
                        "Student"
                          ? "block"
                          : "none",
                    }}
                  >
                    <div className="flex p-2">
                      <div div className="w-1/2 mr-5">
                        <div>
                          <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                            <div class="w-auto px-3 py-3">
                              <h3 class="text-lg font-semibold text-[#0F3D79]">
                                Career Objectives
                              </h3>
                            </div>
                            {/* <div className="text-md font-medium text-gray-500">
                              <p>
                                {
                                  current_individual_user?.profile_completion
                                    ?.career_objectives
                                }
                              </p>
                            </div> */}

                            <div className="w-full">
                              <div
                                className="h-full rounded-xl px-3 py-3"
                                style={{ marginLeft: "20px" }}
                              >
                                <div className="mb-8 -mx-4">
                                  <div className="-mb-3 text-start white-space-no-wrap">
                                    <div className="text-md font-medium text-gray-500">
                                      <p>
                                        {
                                          current_individual_user
                                            ?.profile_completion
                                            ?.career_objectives
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="px-3 py-3"
                    style={{
                      display:
                        current_individual_user?.profile_completion
                          ?.category !== "Student"
                          ? "block"
                          : "none",
                    }}
                  >
                    <div className="lg:flex  p-2">
                      <div className="w-100 mr-5">
                        <div className="flex flex-wrap -mx-4 -mb-4 justify-between items-center ml-0">
                          <div className="w-auto mt-1">
                            <h3 className="text-lg font-semibold text-[#0F3D79] mb-4">
                              Career Details
                            </h3>
                          </div>
                        </div>

                        <div className="mt-5 text-md font-medium text-gray-500">
                          <p class=" text-md font-semibold text-gray-800">
                            {
                              current_individual_user?.profile_completion
                                ?.experience_details_1?.company_name1
                            }
                          </p>
                          <p>
                            {
                              current_individual_user?.profile_completion
                                ?.experience_details_1?.designation1
                            }
                          </p>

                          <p>
                            {
                              current_individual_user?.profile_completion
                                ?.experience_details_1?.company_location1
                            }
                          </p>
                          <p>
                            {current_individual_user?.profile_completion
                              ?.experience_details_1?.expStart_year1 &&
                            current_individual_user?.profile_completion
                              ?.experience_details_1?.expEnd_year1
                              ? `${dayjs(
                                  current_individual_user.profile_completion
                                    .experience_details_1.expStart_year1
                                ).format("YYYY")}-${
                                  current_individual_user.profile_completion
                                    .experience_details_1.expEnd_year1 ===
                                  "present"
                                    ? "Present" // Handle "present" case
                                    : dayjs(
                                        current_individual_user
                                          .profile_completion
                                          .experience_details_1.expEnd_year1
                                      ).format("YYYY")
                                }`
                              : ""}
                          </p>
                        </div>

                        <div className="mt-5 text-md font-medium text-gray-500">
                          <p class=" text-md font-semibold text-gray-800">
                            {
                              current_individual_user?.profile_completion
                                ?.experience_details_2?.company_name2
                            }
                          </p>
                          <p>
                            {
                              current_individual_user?.profile_completion
                                ?.experience_details_2?.designation2
                            }
                          </p>

                          <p>
                            {
                              current_individual_user?.profile_completion
                                ?.experience_details_2?.company_location2
                            }
                          </p>
                          <p>
                            {current_individual_user?.profile_completion
                              ?.experience_details_2?.expStart_year2 &&
                            current_individual_user?.profile_completion
                              ?.experience_details_2?.expEnd_year2
                              ? `${dayjs(
                                  current_individual_user.profile_completion
                                    .experience_details_2.expStart_year2
                                ).format("YYYY")}-${dayjs(
                                  current_individual_user.profile_completion
                                    .experience_details_2.expEnd_year2
                                ).format("YYYY")}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="w-full lg:w-[25%] px-3"
              // style={{ display: type == "" ? "block" : "none" }}
            >
              {/* <Skills current_individual_user={current_individual_user} individualtoken={individualtoken} /> */}
              <div class=" p-6 bg-gray-100 rounded-xl h-[300px]">
                <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                  <div class="w-auto px-4">
                    <h3 class="text-lg font-semibold text-[#0F3D79] mb-4">
                      Achievement and Awards
                    </h3>
                  </div>
                </div>
                <div className="mt-5">
                  <p class=" text-md font-semibold text-gray-800">
                    {
                      current_individual_user?.profile_completion
                        ?.award_details_1?.award1
                    }
                  </p>
                </div>
                <div className=" flex mt-2">
                  {current_individual_user?.profile_completion?.award_details_1
                    ?.awarded_year1 ? (
                    <h4 class="text-lg font-semibold text-[#0F3D79]">
                      {" "}
                      Year :
                    </h4>
                  ) : null}

                  <p class="ml-2 text-lg font-semibold text-gray-800">
                    {current_individual_user?.profile_completion
                      ?.award_details_1?.awarded_year1
                      ? dayjs(
                          current_individual_user?.profile_completion
                            ?.award_details_1?.awarded_year1
                        ).format("YYYY")
                      : ""}
                  </p>
                </div>
                <div className="mt-2">
                  <p class=" text-md font-semibold text-gray-800">
                    {
                      current_individual_user?.profile_completion
                        ?.achivement_details_1?.achivement1
                    }
                  </p>
                </div>
                <div className=" flex mt-2">
                  {current_individual_user?.profile_completion
                    ?.achivement_details_1?.achived_year1 ? (
                    <h4 class="text-lg font-semibold text-[#0F3D79]">
                      {" "}
                      Year :
                    </h4>
                  ) : null}

                  <p class="ml-2 text-lg font-semibold text-gray-800">
                    {current_individual_user?.profile_completion
                      ?.achivement_details_1?.achived_year1
                      ? dayjs(
                          current_individual_user?.profile_completion
                            ?.achivement_details_1?.achived_year1
                        ).format("YYYY")
                      : ""}
                  </p>
                </div>
              </div>
              <br />
              <div class=" p-6 bg-gray-100 rounded-xl h-[300px]">
                <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                  <div class="w-auto px-4">
                    <h3 class="text-lg font-semibold text-[#0F3D79] mb-4">
                      Skills
                    </h3>
                  </div>
                </div>

                <div className="mt-5">
                  <p className="ml-2 text-lg font-semibold text-gray-800">
                    {current_individual_user?.profile_completion?.skill_details1
                      ?.skill_year1
                      ? // Check if the skill year is a non-empty valid string
                        current_individual_user?.profile_completion
                          ?.skill_details1?.skill_year1 === "present"
                        ? "Present"
                        : current_individual_user?.profile_completion
                            ?.skill_details1?.skill_year1
                      : ""}
                  </p>
                </div>
                <div className="flex mt-2">
                  {current_individual_user?.profile_completion?.skill_details1
                    ?.skill_year1 ? (
                    <h4 className="text-lg font-semibold text-[#0F3D79]">
                      Details:
                    </h4>
                  ) : null}
                  <p class=" text-md font-semibold pl-2 text-gray-800">
                    {
                      current_individual_user?.profile_completion
                        ?.skill_details1?.skill1
                    }
                  </p>
                </div>

                <div className="mt-5">
                  <p className="ml-2 text-lg font-semibold text-gray-800">
                    {current_individual_user?.profile_completion?.skill_details2
                      ?.skill_year2
                      ? // Check if the skill year is a non-empty valid string
                        current_individual_user?.profile_completion
                          ?.skill_details2?.skill_year2 === "present"
                        ? "Present"
                        : current_individual_user?.profile_completion
                            ?.skill_details2?.skill_year2
                      : ""}
                  </p>
                </div>
                <div className="flex mt-2">
                  {current_individual_user?.profile_completion?.skill_details2
                    ?.skill_year2 ? (
                    <h4 className="text-lg font-semibold text-[#0F3D79]">
                      Details :
                    </h4>
                  ) : null}
                  <p class=" text-md font-semibold text-gray-800">
                    {
                      current_individual_user?.profile_completion
                        ?.skill_details2?.skill2
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
